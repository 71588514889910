import { breakpoints } from '@utils/variables';

export const debounce = (fn, ms) => {
  let timer;
  // eslint-disable-next-line no-unused-vars
  return (_) => {
    clearTimeout(timer);
    // eslint-disable-next-line no-unused-vars,no-shadow
    timer = setTimeout((_) => {
      timer = null;
      // eslint-disable-next-line no-undef
      fn.apply(this, arguments);
    }, ms);
  };
};

export const trimmer = (str, maxLen = '70') => {
  if (str.length <= maxLen) return str;

  return str.substr(0, str.lastIndexOf(' ', maxLen));
};

export const array = (length) => Array.from({ length }, (v, k) => k + 1);

export const disableEnter = (e) => {
  if (e.keyCode === 13) e.preventDefault();
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string') return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const currentBreakpoint = (windowWidth) => {
  const breakpoint = Object.values(breakpoints).reverse().find((value) => value <= windowWidth);
  const breakpointKey = Object.keys(breakpoints).find((key) => breakpoints[key] === breakpoint);

  return breakpointKey;
};
