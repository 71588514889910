import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { trimmer } from '@utils/helpers';

import Text from '@components/text';
import Link from '@components/link';
import PdfIcon from '@images/pdf-icon.svg';

const createLinesArr = (text) => {
  const lines = [];
  const iterations = Math.ceil(text.length / 70);
  let textLeft = text;

  for (let i = 0; i < iterations; i += 1) {
    const line = trimmer(textLeft);
    lines.push(line);
    textLeft = textLeft.replace(line, '');
  }

  return lines;
};

const Wrapper = tw(Link)`
  flex flex-row py-10 text-left
  lg:items-center lg:py-15
`;

const Icon = styled(PdfIcon)`
  .fill {
    ${tw`transition duration-300`}
  }

  ${Wrapper}:hover & .fill {
    ${tw`lg:fill-redDark`}
  }
`;

const TextWrapper = tw.div`flex flex-col items-start`;

const StyledText = styled(Text)`
  ${tw`relative ml-15 text-black transition-colors duration-300`}

  ::after {
    content: '';
    ${tw`absolute bottom-4 left-0 w-full h-1 bg-redDark opacity-0 transition-opacity duration-300`}
  }

  ${Wrapper}:hover & {
    ${tw`lg:text-redDark`}
    
    ::after {
      ${tw`lg:opacity-70`}
    }
  }
`;

const Item = ({ rule }) => {
  const lines = createLinesArr(rule.name);

  return (
    <Wrapper to={rule.file.asset.url} isExternal>
      <Icon />
      <TextWrapper>
        {lines.map((line) => (
          <StyledText
            key={line}
            fontSizes={16}
            fontWeight={300}
            letterSpacing={20}
            lineHeight={1.5}
          >
            {line}
          </StyledText>
        ))}
      </TextWrapper>
    </Wrapper>
  );
};

Item.propTypes = {
  rule: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(
        PropTypes.objectOf(
          PropTypes.string,
        ),
      ),
    ]),
  ).isRequired,
};

export default Item;
