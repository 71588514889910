import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';

import Text from '@components/text';
import Item from '@components/rules/item';

const Wrapper = tw.div`py-20 lg:py-40`;

const Subtitle = tw(Text)`mb-10 lg:mb-15`;

const Items = tw.div`flex flex-col items-start`;

const List = ({ rules }) => (
  <Wrapper>
    <Subtitle fontSizes={16} fontWeight={600} letterSpacing={20}>
      {rules.name}
    </Subtitle>
    <Items>
      {rules.files.map((rule) => <Item key={rule.id} rule={rule} />)}
    </Items>
  </Wrapper>
);

List.propTypes = {
  rules: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.objectOf(
              PropTypes.objectOf(
                PropTypes.string,
              ),
            ),
          ]),
        ),
      ),
    ]),
  ).isRequired,
};

export default List;
