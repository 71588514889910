import React from 'react';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import LayoutConcrete from '@layouts/concrete';
import SEO from '@components/seo.jsx';
import Text from '@components/text';
import List from '@components/rules/list';

const Container = tw.div`
  relative z-1 max-w-xs mx-auto px-30 pt-70 text-center 
  sm:max-w-480
  lg:max-w-full lg:mb-50 lg:px-0 lg:pt-0 lg:text-left
`;

const Rules = () => {
  const intl = useIntl();
  const { sanitySetOfRules } = useStaticQuery(graphql`
    query {
      sanitySetOfRules(name: {eq: "Regulaminy"}) {
        rules_pl {
          id
          name
          files {
            id
            name
            file {
              asset {
                url
              }
            }
          }
        }
        rules_en {
          id
          name
          files {
            id
            name
            file {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `);

  return (
    <LayoutConcrete>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.rules' })}
      />
      <Container>
        <Text
          color="black"
          fontFamily="bebasNeue"
          fontSizes={[42, 56]}
          fontWeight={600}
          lineHeight={1.15}
          letterSpacing={[53, 70]}
        >
          <FormattedMessage id="rules.title" />
        </Text>
        {sanitySetOfRules[`rules_${intl.locale}`].map((rules) => (
          <List key={rules.id} rules={rules} />
        ))}
      </Container>
    </LayoutConcrete>
  );
};

export default Rules;
